import React from "react"
import cntl from "cntl"

import { classNames } from "../utils/constants"
import {
  html,
  css,
  javascript,
  typescript,
  warning,
  star,
} from "../utils/icons"

type CardProps = {
  name: string
  description: string
  stargazers: string
  issues: string
  language: string
}

const Card = ({
  name,
  description,
  stargazers,
  issues,
  language,
}: CardProps) => {
  const getIcon = () => {
    switch (language) {
      case "TypeScript":
        return typescript
      case "JavaScript":
        return javascript
      case "HTML":
        return html
      case "CSS":
        return css
      default:
        return ""
    }
  }

  return (
    <div className={wrapper}>
      <a
        href={`https://github.com/srm-kzilla/${name}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={titleContainer}>
          <h2 className={title}>{name}</h2>
          <div className={languageIconContainer}>
            {getIcon() && (
              <img
                src={getIcon()}
                className={languageIcon}
                alt={`${language}`}
              />
            )}
          </div>
        </div>
        <h4 className={descriptionText}>{description}</h4>
        <div className={bottomDivContainer}>
          <div className={issueContainer}>
            <img src={warning} className={icon} alt="warning" />
            <p className={issueText}>{issues} open issues</p>
          </div>
          <div className={starContainer}>
            <img src={star} className={icon} alt="star" />
            <p className={starText}>{stargazers}</p>
          </div>
        </div>
      </a>
    </div>
  )
}

const wrapper: classNames = cntl`
  bg-light-blue 
  rounded-sm 
  p-8 
  min-h-full 
  relative
  md:mr-4 
`
const titleContainer: classNames = cntl`
  flex 
  content-between
`
const title: classNames = cntl`
  font-inter 
  font-bold 
  w-1/2 
  text-pink 
  text-2xl 
  py-2
`
const languageIconContainer: classNames = cntl`
  flex 
  w-1/2 
  justify-end 
  content-end
`
const languageIcon: classNames = cntl`
  h-8
`
const descriptionText: classNames = cntl`
  font-inter 
  text-grey 
`
const bottomDivContainer: classNames = cntl`
  flex 
  py-5 
  m-4
`
const issueContainer: classNames = cntl`
  flex 
  absolute 
  bottom-0 
  left-0 
  bg-dark-blue 
  p-2 
  m-5
  rounded-full 
`
const issueText: classNames = cntl`
  font-inter 
  px-2 
  font-bold 
  text-grey
`
const icon: classNames = cntl`
  h-4 
  my-1 
  mx-1
`
const starContainer: classNames = cntl`
  absolute 
  flex 
  bottom-0 
  right-0 
  p-8  
`
const starText: classNames = cntl`
  font-inter 
  text-grey
`

export default Card
