import React, { useState, useEffect } from "react"
import axios from "axios"
import cntl from "cntl"

import { classNames } from "../utils/constants"
import Card from "./Card"
import { REPOSITORIES } from "../utils/constants"

const Projects = () => {
  const [repoData, setRepoData] = useState(null)

  useEffect(() => {
    axios
      .get("https://api.github.com/repos/srm-kzilla")
      .then(function(response) {
        console.log(response.headers)
      })
    let promiseArray = REPOSITORIES.map(url => axios.get(url))
    axios.all(promiseArray).then(function(results) {
      setRepoData(results.map(r => r.data))
    })
  }, [])

  if (!repoData) {
    return <div>Loading...</div>
  }

  return (
    <div id="projects" className={wrapper}>
      <h4 className={heading}>Projects to contribute</h4>
      <div className={projectContainer}>
        {repoData.map(data => (
          <div className={repoContainer}>
            <Card
              key={data.id}
              name={data.name}
              description={data.description}
              stargazers={data.stargazers_count}
              issues={data.open_issues_count}
              language={data.language}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const wrapper: classNames = cntl`
  m-10 
  md:mx-40
`
const heading: classNames = cntl`
  my-5 
  text-grey 
  font-inter 
  text-3xl 
  font-bold
`
const projectContainer: classNames = cntl`
  w-full
  md:flex 
  md:flex-wrap 
`
const repoContainer: classNames = cntl`
  w-full 
  my-4 
  cursor-pointer 
  hover:shadow-lg
  md:w-1/3 
`

export default Projects
