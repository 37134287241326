import React from "react"

import {
  Navbar,
  Hero,
  Projects,
  Contribute,
  Event,
  Footer,
} from "../components"

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Contribute />
      <Event />
      <Projects />
      <Footer />
    </>
  )
}

export default Home
