export const TIMELINE = [
  {
    topic: "Hacktoberfest- Checked",
    description:
      "Celebrate open source as we kick start the Hacktoberfest. Be a part of this event to learn the nitty-gritty of open source. Aniruddha Chatterjee, an Associate lead at SRMKZILLA will take you on a tour of the magical land of opensource.",
    time: "1700 - 1705",
  },
  {
    topic: "Changing World. Changing Versions",
    description:
      "Ever wondered what version control is? Why is it vital to be up to date with version control? Don't you worry because we've got you covered :D    ",
    time: "1705 - 1705",
  },
  {
    topic: "Bit closer towards Git",
    description:
      "What is Git all about? How does it work? If these are questions that keep you up at night, don't you worry because we've got all the answers you need from the very basic!",
    time: "1725 - 1705",
  },
  {
    topic: "GitHub the Geekhub?",
    description:
      "Wondered what the hype about Github is? We will help you kick start your exploration with Basic commands and your first Pull Request!",
    time: "1745 - 1705",
  },
  {
    topic: "For the Love of Open Source",
    description:
      "Open source makes source codes approachable and helps the developer community expand their horizons. Learn how Github implements it and a lot more.",
    time: "1745 - 1705",
  },
  {
    topic: "Hacktoberfest",
    description:
      "Open source is changing the world - one pull request at a time. This fest is all about expanding the horizons of the global developer community. Be a part and make a difference. Learn about the rules in the event.",
    time: "1745 - 1705",
  },
  {
    topic: "Nothing is perfect",
    description:
      "What are Issues? Explore the issues on Github with us and learn how to resolve them.",
    time: "1745 - 1705",
  },
  {
    topic: "Introspect. Identify. Implement",
    description:
      "Join us on a hands-on session as we identify SRMKZILLA GitHub repository issues and fix them up with you.",
    time: "1745 - 1705",
  },
]
