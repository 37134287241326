import React from "react"
import cntl from "cntl"

import {
  hacktoberfest,
  tagline_hacktoberfest,
  hacktoberfest_asset_leaf,
} from "../utils/icons"
import { classNames } from "../utils/constants"

const Hero = () => {
  return (
    <div className={wrapper}>
      <img
        className={heroAsset}
        src={hacktoberfest_asset_leaf}
        alt="hacktoberfest_asset"
      />
      <div className={heroTextWrapper}>
        <h1 className={heroTextMain}>SRMKZILLA</h1>
        <h1 className={heroTextMiddle}>joins</h1>
        <div className={heroImageWrapper}>
          <img
            src={hacktoberfest}
            className={heroImage}
            alt="hacktoberfest-logo"
          />
        </div>
      </div>
      <div>
        <img
          className={tagline}
          src={tagline_hacktoberfest}
          alt="tagline_hacktoberfest"
        />
      </div>
    </div>
  )
}

const wrapper: classNames = cntl`
  relative
  text-center 
  my-10 
  md:flex 
  md:my-20
`
const heroAsset: classNames = cntl`
  absolute 
  -left-3 
  w-24 
  opacity-50
  md:w-64 
`
const heroTextWrapper: classNames = cntl`
  m-auto
`
const heroTextMain: classNames = cntl`
  text-5xl 
  text-grey 
  font-inter 
  font-bold
  md:text-6xl 
`
const heroTextMiddle: classNames = cntl`
  text-4xl 
  text-grey 
  font-yellowtail
  md:text-6xl 
`
const heroImageWrapper: classNames = cntl`
  justify-center 
  align-baseline
`
const heroImage: classNames = cntl`
  h-40 
  md:h-64 
  inline
`
const tagline: classNames = cntl`
  m-10 
  my-20 
  right-5 
  bottom-0 
  h-16 
  transform 
  rotate-10
  md:m-0 
  md:absolute 
  md:my-0 
`

export default Hero
