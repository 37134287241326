import React from "react"
import cntl from "cntl"

import { hacktoberfest_asset_branch } from "../utils/icons/index"
import { TIMELINE } from "../utils/data"
import { classNames } from "../utils/constants"

const Event = () => {
  return (
    <div id="event" className={wrapper}>
      <h4 className={title}>October 8, 2020</h4>
      <div className={timelineWrapper}>
        {TIMELINE.map(t => {
          return (
            <div className={timelineContainer}>
              <img
                className={asset}
                src={hacktoberfest_asset_branch}
                alt="hacktoberfest_asset"
              />
              <div className={timelineTextContainer}>
                <h4 className={topic}>{t.topic}</h4>
                <h5 className={description}>{t.description}</h5>
                <p className={time}>{t.time}</p>
              </div>
            </div>
          )
        })}
        <a
          href="https://organize.mlh.io/participants/events/4345-hacktoberfest-with-srmkzilla"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          <button className={button}>REGISTER NOW</button>
        </a>
      </div>
    </div>
  )
}

const wrapper: classNames = cntl`
  m-10 
  mb-4 
  bg-light-blue 
  md:mx-40 
  md:my-20 
  md:p-6 rounded-sm
`
const title: classNames = cntl`
  my-10 
  py-6 
  text-center 
  text-satin-blue 
  font-inter 
  text-3xl 
  font-bold
  md:py-0 
`
const timelineWrapper: classNames = cntl`
  w-full 
  text-grey 
  font-inter
`
const timelineContainer: classNames = cntl`
  flex 
  my-4 
  px-6
  rounded-md 
  mx-5
`
const asset: classNames = cntl`
  h-4 
  flex-shrink-0 
  my-3
`
const timelineTextContainer: classNames = cntl`
  inline-block 
  mx-6
`
const topic: classNames = cntl`
  text-2xl 
  font-bold
`
const description: classNames = cntl`
  text-l 
  text-satin-blue
`
const time: classNames = cntl`
  text-l 
  font-light 
  text-pink 
`
const button: classNames = cntl`
  w-10/12 
  transition 
  duration-200 
  ease-in-out 
  bg-bright-blue 
  hover:bg-pink 
  cursor-pointer 
  font-inter 
  p-3 
  my-6 
  rounded-sm 
  font-bold
  md:w-1/4 
`
const link: classNames = cntl`
  flex 
  justify-center
`

export default Event
