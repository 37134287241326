import React from "react"
import cntl from "cntl"

const Footer = () => {
  return (
    <div className={wrapper}>
      <h4 className={footerText}>
        in the spirit of openness, for the love of open source.
      </h4>
      <h4 className={footerText}>
        crafted with
        <span aria-label="heart" role="img">
          🧡
        </span>
        by your friends on the SRMKZILLA team
      </h4>
    </div>
  )
}

const wrapper = cntl`
  text-center
  my-10
`
const footerText = cntl`
  text-sm 
  px-6 
  md:text-md 
  block 
  font-inter 
  font-normal 
  text-satin-blue
`

export default Footer
