export const GITHUB_API = {
  BASE_URL: "https://api.github.com/repos/srm-kzilla",
}

export const REPOSITORIES = [
  `${GITHUB_API.BASE_URL}/mosaic`,
  `${GITHUB_API.BASE_URL}/kzilla.xyz`,
  `${GITHUB_API.BASE_URL}/SRMKZILLA-v-2.0`,
  `${GITHUB_API.BASE_URL}/violet-air`,
  `${GITHUB_API.BASE_URL}/mozarc`,
  `${GITHUB_API.BASE_URL}/mellow-vscode-theme`,
]

export type classNames = string
