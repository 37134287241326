import React from "react"
import cntl from "cntl"

import { classNames } from "../utils/constants"

const Contribute = () => {
  return (
    <div id="contribute" className={wrapper}>
      <h4 className={heading}>Why Open Source?</h4>
      <p className={paragraph}>
        Open-source software is at the heart of SRMKZILLA. At SRMKZILLA, we’ve
        always used open source technologies to innovate. We want to give
        something back; we enjoy being a part of the community. We often release
        code or share best-practices we developed. But sometimes, it’s just fun
        and interesting code.
      </p>
    </div>
  )
}

const wrapper: classNames = cntl`
  m-10 
  my-24 
  md:mx-40 
  md:my-20
`
const heading: classNames = cntl`
  my-5 
  text-grey 
  font-inter 
  text-3xl 
  font-bold
`
const paragraph: classNames = cntl`
  text-satin-blue 
  font-inter 
  text-lg
`

export default Contribute
