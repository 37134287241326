import React from "react"
import { Link } from "react-scroll"
import cntl from "cntl"

import { classNames } from "../utils/constants"

const Navbar = () => {
  return (
    <div className={wrapper}>
      <Link
        activeClass="active"
        to="contribute"
        spy={true}
        smooth={true}
        duration={1000}
      >
        <h4 className={heading}>WHY OPEN SOURCE?</h4>
      </Link>
      <Link
        activeClass="active"
        to="event"
        spy={true}
        smooth={true}
        duration={1000}
      >
        <h4 className={heading}>EVENT</h4>
      </Link>
      <Link
        activeClass="active"
        to="projects"
        spy={true}
        smooth={true}
        duration={1000}
      >
        <h4 className={heading}>PROJECTS</h4>
      </Link>
      <a
        href="https://organize.mlh.io/participants/events/4345-hacktoberfest-with-srmkzilla"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className={button}>REGISTER</button>
      </a>
    </div>
  )
}

const wrapper: classNames = cntl`
  text-center 
  justify-center 
  text-white 
  font-inter 
  p-8 
  font-bold
  md:flex 
  md:justify-evenly 
`
const heading: classNames = cntl`
  transition 
  duration-200 
  ease-in-out 
  p-2 
  hover:text-bright-blue 
  cursor-pointer
`
const button: classNames = cntl`
  transition 
  duration-200 
  ease-in-out 
  bg-bright-blue 
  hover:bg-pink 
  cursor-pointer 
  font-inter 
  p-2  
  px-8 
  font-bold 
  rounded-sm
`

export default Navbar
